
/* You can add global styles to this file, and also import other style files */

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, 
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked{
    color: #fff;
    background-color: #2473b4;
    margin-bottom: 1px;
}

/* ng-select validate style */

/* Border Color */
.validate.ng-select.ng-invalid.is-invalid.ng-touched .ng-select-container{
  border-color: red !important;
}

.validate.ng-select.ng-valid.is-valid .ng-select-container{
  border-color: #40C057;
}
/* Arrow Color */
.validate.ng-select.ng-invalid.ng-touched.is-invalid .ng-arrow-wrapper .ng-arrow{
  border-color: red transparent transparent !important;
}

.validate.ng-select.ng-valid.is-valid .ng-arrow-wrapper .ng-arrow{
  border-color: #40C057 transparent transparent !important;
}

/* Placeholder Color */
.validate.ng-select.ng-invalid.is-invalid.ng-touched .ng-select-container .ng-placeholder{
  color: red !important;
}

.validate.ng-select.ng-valid.is-valid .ng-select-container .ng-placeholder{
  color: #40C057 !important;
}

/* end */



  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }

  ::-webkit-scrollbar-thumb {
    background: var(--primary); 
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #102f49;
  }

/* DateRange Picker */

.dp-hidden {
    width: 0;
    margin: 0;
    border: none;
    padding: 0;
  }
  .custom-day {
    text-align: center;
    padding: 0.185rem 0.25rem;
    display: inline-block;
    height: 2rem;
    width: 2rem;
  }
  .custom-day.focused {
    background-color: #e6e6e6;
  }
  .custom-day.range,
  .custom-day:hover {
    background-color:var(--primary);
    color: white;
  }
  .custom-day.faded {
    background-color: #f4fffb;
    border : 1px solid var(--primary); 
    color: var(--primary);
  }
  
  @media screen and (max-width: 518px) {
    .ngb-dp-header , .ngb-dp-month-name, .ngb-dp-weekdays{
      background-color: white !important;
    }
    .ngb-dp-months{
      display:block !important;
    }
    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday{
      width: 2.6rem !important;
    }
    .custom-day {
      width: 2.6rem !important;
    }
  }
  
  @media screen and (max-width: 366px) {
    .ngb-dp-day, .ngb-dp-week-number, .ngb-dp-weekday{
      width: 2.15rem !important;
    }
    .custom-day {
      width: 2.15rem !important;
    }
  }
  
  /* end */

  /* DataTables */

  .ngx-datatable.height .datatable-row-group{
    align-items: center !important;
  }

  .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap {
    padding-right: 30px;
    white-space: nowrap;
    font-weight: 700
}

.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sort-active.sort-asc .datatable-header-cell-template-wrap:after,.ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sort-active.sort-desc .datatable-header-cell-template-wrap:before {
    opacity: 1
}

.ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
    margin-top: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #f3f3f3;
    padding-bottom: 35px
}

datatable-row-wrapper:last-of-type .datatable-body-row {
    border-bottom: initial!important
}

.ngx-datatable .custom-control.custom-checkbox {
    margin-top: -4px
}

.data-table-scrollable .datatable-body .datatable-scroll {
    padding-bottom: 20px
}

.data-table-rows .ngx-datatable {
    margin-left: -15px;
    padding-left: 15px;
    margin-right: -15px;
    padding-right: 15px
}

.data-table-rows .ngx-datatable .datatable-body .datatable-row-wrapper ,
.ngx-datatable.bootstrap .datatable-body .empty-row {
    background: #fff;
    border-radius: .75rem;
    border: initial;
    margin-top: .5rem;
    margin-bottom: .5rem;
    box-shadow: 0 1px 15px rgba(0,0,0,.04),0 1px 6px rgba(0,0,0,.04)
}

.data-table-rows .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row {
    padding-bottom: 2.5rem;
    border: initial;
    margin-top: 0;
    margin-bottom: 0;
    width: 100%!important
}

.data-table-rows .ngx-datatable .datatable-body .datatable-row-wrapper,.data-table-rows .ngx-datatable .datatable-body .datatable-scroll,.data-table-rows datatable-selection {
    width: 100%!important
}

.data-table-rows .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-body-group-cell {
    padding-left: 1.5rem
}
.datatable-header-cell-label .draggable{
    cursor: pointer;
}

.data-table-rows .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap:before {
    right: auto;
    left: .5em
}

.data-table-rows .ngx-datatable.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell.sortable .datatable-header-cell-template-wrap:after {
    right: auto;
    left: 1em
}

.data-table-responsive .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-row-center datatable-body-cell:first-of-type {
    padding-left: 0!important
}

.data-table-responsive .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-row-center datatable-body-cell:first-of-type .glyph-icon {
    position: absolute;
    left: 12px;
    top: 4px
}

.data-table-responsive .datatable-row-detail .detail-content {
    padding-left: 35px;
    padding-right: 35px
}

.ngx-datatable .datatable-body .datatable-row-detail{
    height : auto !important ;
}

.data-table-rows .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row{
  padding-bottom: 0 !important;
}

.height .datatable-body-cell , .height  .datatable-body-row{
  height: fit-content !important;
}

@media screen and (max-width: 1199px) {
  .ngx-datatable.height.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2), .ngx-datatable.height.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell:nth-child(2) {
      min-width: 75% !important;
  }
}

@media screen and (max-width: 634px) {
  .ngx-datatable.height.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2), .ngx-datatable.height.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell:nth-child(2) {
      min-width: 50% !important;
  }
}

@media screen and (max-width: 375px) {
  .ngx-datatable.height.fixed-header .datatable-header .datatable-header-inner .datatable-header-cell:nth-child(2), .ngx-datatable.height.fixed-row .datatable-scroll .datatable-body-row .datatable-body-cell:nth-child(2) {
      min-width: 45% !important;
  }
}

.limit-w-o{
  text-wrap: wrap;
}

.data-table-responsive .ngx-datatable.fixed-row .datatable-scroll .datatable-body-row .datatable-row-center datatable-body-cell:first-of-type{
  padding-left: 0.5rem !important;
  margin-bottom: auto;
  margin-top: auto;
}

.PerPage{
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}



/* Animation */

.table-skeleton .row{
  background-color: #F7F7F8 !important;
  margin-right: 0.1rem;
  margin-left: 0.1rem;
}

.table-skeleton .row span{
  /* color: #595959 !important; */
  margin: 4px 0px;
  font-size: 13px;
  font-weight: 700;
}

.table-skeleton .skeleton-loader{
  height: 40px !important;
}

.tiles .skeleton-loader.circle{
  width: 100% !important;
  height: 150px !important;
  margin: 0px !important;
  border-radius: 10px !important;
}



/* End */

/* General */

.padding-match-auto{
    position: relative;
    padding-right: 15px;
    padding-left: 15px;
}