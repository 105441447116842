// Global Overrides Template Level Only

//------- ng-accordion -------
.accordion {
  --bs-accordion-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform .2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%232473b4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
.card {
  margin: 0;
  background-color: transparent !important;
  border-bottom: 1px solid $border-color !important;
  
  &:not(:last-of-type) {
    .card-header {
      border-bottom: 1px solid $border-color;
    }
  }

  // &:last-of-type {
  //   .card-header button {
  //     padding-bottom: 0;
  //   }

  //   .card-body {
  //     padding-bottom: 0;
  //   }
  // }

  .card-header {
    padding: 0;

    button {
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;
      padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
      font-size: 1rem;
      color: var(--dark);
      text-align: left;
      border: 0;
      border-radius: 0;
      overflow-anchor: none;
      transition: var(--bs-accordion-transition);
      &:after {
        flex-shrink: 0;
        width: var(--bs-accordion-btn-icon-width);
        height: var(--bs-accordion-btn-icon-width);
        margin-left: auto;
        content: "";
        background-image: var(--bs-accordion-btn-icon);
        background-repeat: no-repeat;
        background-size: var(--bs-accordion-btn-icon-width);
        transition: var(--bs-accordion-btn-icon-transition)
      }
      &:not(.collapsed){
        color: var(--primary);
        font-weight: 600;
      }
      &:not(.collapsed):after {
        background-image: var(--bs-accordion-btn-active-icon);
        transform: var(--bs-accordion-btn-icon-transform)
      }
      &:hover {
        box-shadow: none;
        z-index: 2;
      }
      &:focus {
        z-index: 3;
        // border-color: var(--bs-accordion-btn-focus-border-color);
        outline: 0;
      }
    }
    @media (prefers-reduced-motion: reduce) {
      button:after {
          transition: none
      }
    }
    @media (prefers-reduced-motion: reduce) {
      button {
          transition: none
      }
    }
  }
}
}

//------- horizontal timeline -------
.cd-horizontal-timeline .events-content li {
  transform: translateX(0);
}

//------- time picker -------
.ngb-tp-input-container button:hover {
  box-shadow: none;
}

//------- Chartist Tooltip -------
.chartist-tooltip:not(.tooltip-show) {
  padding: 0;

  &:before {
    border: 0;
  }
}


// for dark and transparent layouts
html body.layout-dark {

  // Navbar search
  .search-list {
    background: $bg-color-dark;

    .first-active-item .option {
      background: $dl-bg-color;
    }

    .option {
      color: $dl-text-color;

      &:hover {
        background: $dl-bg-color;
      }
    }

    .accordion .card {
      box-shadow: none;
    }

    &:not(.layout-transparent) {
      .accordion .card .card-header {
        border-color: $dl-border-color;
      }
    }

    &.layout-transparent {
      .accordion .card .card-header {
        border-color: $tl-border-color;

        button:not([disabled]) {
          color: map-get($primary-color, lighten-4);
        }
      }
    }
  }
}
